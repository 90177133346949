import Vue from 'vue';
import axios from 'axios';
import router from './router'
import COS from 'cos-js-sdk-v5'
import CryptoJS from 'crypto-js'

Vue.use(router)

/* 后端api地址 */
Vue.prototype.ApiUrl = 'https://total.api.jk125.com/index.php'; //线上api地址
Vue.prototype.serveUrl = 'https://total.api.jk125.com'; //线上图片地址 

if (document.domain == 'localhost') {
	Vue.prototype.ApiUrl = 'http://api.total.cc/index.php'; //本地api地址
	// Vue.prototype.ApiUrl = 'http://api.total.cc:8080'; //本地api地址
	Vue.prototype.serveUrl = 'http://api.total.cc';
	if (document.location.port == '8891') {
		Vue.prototype.ApiUrl = 'http://total.api.sz901.cn/index.php';
		Vue.prototype.serveUrl = 'http://total.api.sz901.cn';
	}
}

/*
  路由跳转 url 跳转路由地址 string 必须 query 额外参数 object 可选
 */
Vue.prototype.toUrl = function(url, query) {
	const queryParams = {
		...query
	}
	this.$router.push({
		path: url,
		query: queryParams
	});
}
/*
  url 请求地址 string 必须
  data 请求参数 object 可选
  headers 请求头 默认不需要传值
 */
Vue.prototype.requestApi = function(url, data, headers) {
	let header = Object.assign({
		'content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
	}, headers);
	axios.defaults.baseURL = '/admin';
	return new Promise((resolve) => {
		axios.interceptors.request.use(
			config => {
				const token = this.getToken();
				token && (config.headers.authorize = token);
				return config;
			}, error => {
				return Promise.error(error);
			});
		axios({
			method: typeof data == "object" ? 'POST' : 'GET',
			url: this.ApiUrl + url,
			data: data ? data : {},
			headers: header,
			responseType: 'json',
		}).then(res => {
			if (res.status == 200) {
				if (res.data.status == 403) {
					this.alertErr(res.data.msg);
				} else if (res.data.status == 401) {
					this.toUrl('/login')
					this.alertErr(res.data.msg);
				} else {
					resolve(res.data);
				}
			}
		}).catch(err => {
			this.alertErr('请求失败！', 4.5)
			console.log(err)
		})
	})
}
/* 获取token */
var delay_status = 0;
var out_time = 43200;
Vue.prototype.getToken = function() {
	let token = localStorage.getItem('store_token');
	if (token && !delay_status) {
		let store_out_time = localStorage.getItem('store_out_time'),
			now = new Date().getTime();
		now = parseInt(now.toString().substr(0, 10));
		if (!store_out_time || (store_out_time - now) < (out_time - 600)) {
			delay_status = 1;
			this.requestApi('/adm/delay').then(function(res) {
				if (res.status == 1) {
					localStorage.setItem('store_out_time', res.out_time);
					delay_status = 0;
				} else {
					delay_status = 0;
				}
			})
		}
	}
	return token;
}

Vue.prototype.alertSucc = function(txt, duration) {
	this.$Message.success({
		content: txt,
		background: true,
		duration: duration ? duration : 2
	})
}
/* 全局错误消息提醒 */
Vue.prototype.alertErr = function(txt, duration) {
	this.$Message.error({
		content: txt,
		background: true,
		duration: duration ? duration : 2
	})
}
Vue.prototype.getDateTime = function(date) {
	date = typeof date != "undefined" ? date : new Date();
	var y = date.getFullYear(),
		m = sendDate(date.getMonth() + 1),
		d = date.getDate(),
		h = sendDate(date.getHours()),
		i = sendDate(date.getMinutes()),
		s = sendDate(date.getSeconds());
	return date = y + '-' + m + '-' + d + ' ' + h + ':' + i + ':' + s;
}

function sendDate(d) {
	if (parseInt(d) < 10) {
		d = '0' + d;
	}
	return d;
}
/**
 * 浮点型计算转换（四舍五入）
 * @param expression 数值或表达式
 * @param digit 转换位数
 */
Vue.prototype.formatFloat = function(expression, digit) {
	var m = Math.pow(10, digit);
	return Math.round(expression * m) / m;
}

// 获取完整的图片地址
Vue.prototype.fullImage = function(img) {
	// 检测传递的图片地址为空
	if (!img) return '';
	var image = img;
	if (img.indexOf('http') < 0) {
		image = img.indexOf('/uploads') != -1 || img.indexOf('/image') != -1 ? this.serveUrl + img : 'https://' +
			img;
	}
	return image;
}

// 生成随机字符串的函数
function generateRandomString(length) {
	let result = '';
	const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	const charactersLength = characters.length;
	for (let i = 0; i < length; i++) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength));
	}
	return result;
}

// 生成文件名的函数
Vue.prototype.generateFileName = function() {
	const timestamp = Date.parse(new Date());
	const randomString = generateRandomString(8);
	const combined = timestamp + randomString;
	// 使用 SHA256 算法对组合字符串进行加密
	const hash = CryptoJS.SHA256(combined).toString(CryptoJS.enc.Hex);
	return hash;
}